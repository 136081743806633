import React from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { AuthRedirect } from "../../components/auth";
import GenericPage from "../../components/generic-page";
import {Redirect} from "@reach/router";
import { filterGraphQLRequestFields } from "../../utils/gql";
import { getLocationByIdQuery, getLocationByIdSelectionPath, getLocationResult } from "../../logic/location";
import { getDocumentsForLocationQuery, getDocumentsForLocationResult, getDocumentsForLocationSelectionPath } from "../../logic/documents";
import DocumentsPanel from "../../components/locations/documents";
import { usePermissions } from "../../logic/permissions";
import {useQuery} from "@apollo/client";
import { useTranslation } from "react-i18next";
import "../../style/portal.scss";
import { Link } from "gatsby";

function Contents({locationId}) {
  // console.log("locationId", locationId)
  
  const {t} = useTranslation();
  const permissions = usePermissions({
    modelName: "location",
    type: "query-field",
  });

  const locationQuery = useQuery(filterGraphQLRequestFields(getLocationByIdQuery, permissions, getLocationByIdSelectionPath), {
    variables: {
      id: locationId,
    },
    skip: permissions.loading,
  });

  const documentPermissions = usePermissions({
    modelName: "document",
    type: "query-field",
  });
  const queryDocuments = useQuery(filterGraphQLRequestFields(getDocumentsForLocationQuery, documentPermissions, getDocumentsForLocationSelectionPath), {
    // fetchPolicy: "network-only",
    variables: {
      locationId,
    },
    skip: documentPermissions.loading,
    // pollInterval: polling ? 1000 : undefined,
    notifyOnNetworkStatusChange: true,
  });
  
  if (documentPermissions.loading || locationQuery.loading) {
    console.log("perms loading ", documentPermissions.loading || locationQuery.loading);
    return (<React.Fragment />);
  }

  const documents = getDocumentsForLocationResult(queryDocuments);
  const location = getLocationResult(locationQuery);
  if (!location) {
    console.log("location not found");
    return (<React.Fragment />);
  }
  const buttons = [];
  const floorPlans = documents.filter((d) => d.floorplan);

  return (<>
    <Container fluid className="no-gutters">
      <Row className="align-items-center justify-content-between">
        <Col xs="auto">
          <h3>{`${location.address}`}</h3>
        </Col>
        <Col xs={"auto"} className={"ml-auto"}>
          <Link to={"/portal"}>{"Back"}</Link>
        </Col>
      </Row>
    </Container>
    <Tabs defaultActiveKey="images">
      <Tab eventKey="images" title="Images">
        <DocumentsPanel
          title={location.address}
          buttons={buttons}
          className="client-location-header details-header fh fh-element"
          documentPermissions={documentPermissions}
          documents={documents.filter((d) => !d.floorplan)}
          location={location} />
      </Tab>
      {floorPlans.length > 0 && (<Tab eventKey="floor-plan" title="Floor Plan">
        <DocumentsPanel
          title={t("location.floor-plan")}
          className=" fh fh-element details-header min-height-300"
          floorPlan
          documentPermissions={documentPermissions}
          documents={floorPlans}
          location={location} />
      </Tab>)}
      
    </Tabs>
  </>);
}


export default function LocationIndex(props) {
  const {location: {search}} = props;
  const id = new URLSearchParams(search).get('id');
  if(!id) {
    return <React.Fragment/>
  }
  return (
    <GenericPage fh>
      <AuthRedirect to={"/login"}>
        <Contents {...props} locationId={id} />
      </AuthRedirect>
    </GenericPage>
  );
}