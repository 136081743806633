import root from "window-or-global";

export function getViewportSize(w) {
  if (!root.document) {
    return {
      w: 1920,
      h: 1080,
    };
  }
  // Use the specified root or the current root if no argument
  w = w || root;
  // This works for all browsers except IE8 and before
  if (w.innerWidth) {
    return {
      w: w.innerWidth - (w.innerWidth - document.body.offsetWidth),
      h: w.innerHeight,
    };
  }
  // For IE (or any browser) in Standards mode
  var d = w.document;
  if (document.compatMode === "CSS1Compat") {
    return {
      w: d.documentElement.clientWidth,
      h: d.documentElement.clientHeight,
    };
  }
  // For browsers in Quirks mode
  return {
    w: d.body.clientWidth,
    h: d.body.clientHeight,
  };
}


export function getElementPosition(el) {
  let rect = el.getBoundingClientRect();
  let scrollLeft = root.pageXOffset || root.document.documentElement.scrollLeft;
  let scrollTop = root.pageYOffset || root.document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, width: rect.width, height: rect.height };
}

export function getImageSizeFromObjectUrl(objectUrl) {
  return new Promise((resolve, reject) => {
    try {
      const img = document.createElement("img");
      img.onload = (e) => {
        try {
          const {height, width} = img;
          return resolve({height, width});
        } catch (e) {
          return reject(e);
        }
      };
      img.onerror = (e) => reject(e);
      img.src = objectUrl;
    } catch (e) {
      return reject(e);
    }
    return undefined;
  });
}
