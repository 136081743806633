import { gql } from "@apollo/client";


export const requestUploadDocumentMutation = gql`mutation requestUploadDocument($locationId: ID!, $fileName: String!,  $fileType: String, $height: Int, $width: Int, $floorplan: Boolean) {
  classMethods {
    Document {
      requestUploadDocument(fileName: $fileName, fileType: $fileType, locationId: $locationId, height: $height, width:$width, floorplan: $floorplan) {
        id
        uploadTarget
      }
    }
  }
}`;
export function requestUploadDocumentResult(result) {
  return result.data?.classMethods?.Document?.requestUploadDocument;
}

export const getDocumentsForLocationSelectionPath = "models.Document.edges.node";
export const getDocumentsForLocationQuery = gql`query getDocumentsForLocation($locationId:ID) {
  models {
    Document(where:{
      locationId: {
        eq: $locationId
      }
    }, orderBy: idASC) {
      edges {
        node {
          id
          name
          floorplan
          description
          released
          published
          processed
          type
          urls
        }
      }
    }
  }
}`;
export function getDocumentsForLocationResult(result) {
  return (result.data?.models?.Document?.edges || []).map((e) => e.node);
}


export const updateDocumentMutation = gql`mutation updateDocument($ids: [ID], $input: DocumentOptionalInput) {
  models {
    Document(update: {
      where: {
        id: {
          in: $ids
        }
      },
      input: $input
    }) {
      id
      name
      description
      released
      published
      processed
      type
      urls
    }
  }
}`;


export const requestUploadFileMutation = gql`mutation requestUploadFile($filePath: String!) {
  classMethods {
    Document {
      requestUploadFile(filePath: $filePath) {
        id
        uploadTarget
      }
    }
  }
}`;

export function requestUploadFileResult(result) {
  return result?.data?.classMethods?.Document?.requestUploadFile;
}
export const getFileUriQuery = gql`query getFileUri($filePath: String!, $download: Boolean, $fileName: String) {
  classMethods {
    Document {
      getFileUri(filePath:$filePath, download: $download, fileName: $fileName)
    }
  }
}`;
export function getFileUriResult(result) {
  return result?.data?.classMethods?.Document?.getFileUri;
}



export const deleteDocumentsMutation = gql`
mutation deleteDocuments($ids: [ID]) {
  models {
    Document(delete: {
      id: {
        in: $ids
      }
    }) {
      id
    }
  }
}`;