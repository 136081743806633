import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import {useDropzone} from "react-dropzone";
import Gallery from "react-photo-gallery";
import {AutoSizer} from "react-virtualized";
// import {v4 as uuid} from "uuid";
import {useMutation, useQuery} from "@apollo/client";
import {deleteDocumentsMutation, getDocumentsForLocationQuery, requestUploadDocumentMutation, requestUploadDocumentResult, updateDocumentMutation} from "../../logic/documents";
import {getImageSizeFromObjectUrl} from "../../utils/browser";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getOperationName } from "@apollo/client/utilities";
import { usePermissions } from "../../logic/permissions";
import { filterGraphQLRequestFields } from "../../utils/gql";
import config from "../../config";
import {v4 as uuid} from "uuid";
import { console } from "window-or-global";

export default function DocumentsPanel(props) {
  const {location, documentPermissions, documents, className, title, floorPlan, buttons} = props;
  const locationId = location.id;
  const {t} = useTranslation();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showDownloadSelection, setShowDownloadSelection] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(undefined);

  const documentMutationPermissions = usePermissions({
    modelName: "document/%",
    type: "mutation",
  });

  const [mutationUpdateDocument] = useMutation(filterGraphQLRequestFields(updateDocumentMutation, documentPermissions, "models.Document"));
  const [mutationDeleteDocuments] = useMutation(deleteDocumentsMutation);



  const [files, setFiles] = useState([]);
  // console.log("files", files);
  const [selected, setSelected] = useState([]);


  const onDrop = useCallback(acceptedFiles => {
    console.log("acceptedFiles", acceptedFiles);
    setFiles(files.concat(acceptedFiles.map((f) => {
      const preview = URL.createObjectURL(f);
      return {
        id: uuid(),
        preview,
        file: f,
      };
    })));
  }, [files]);

  function handleCompleteUpload(e, fileData, id) {
    URL.revokeObjectURL(fileData.preview);
    setFiles(files.map((f) => {
      if (f.id === fileData.id) {
        f.complete = true;
      }
      return f;
    }));
    // setFiles(files.filter((f) => f.id !== fileData.id));
  }
  async function handleErrorUpload(e, fileData, id) {
    console.error("error", {e, fileData, id});
    setFiles(files.map((f) => {
      if (f.id === fileData.id) {
        f.errored = true;
      }
      return f;
    }));
    if (id) { // attempt to delete the failed document, this may fail.
      await mutationDeleteDocuments({
        variables: {
          ids: [id],
        },
      });
    }
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  function handleSetShowPreviewImage(target) {
    return () => {
      setShowPreviewImage(target);
    };
  }

  const imageRenderer = useCallback(
    ({index, left, top, key, photo}) => {
      const state = selected.indexOf(photo.doc.id) > -1;
      return (<SelectedImage
        permissions={documentPermissions}
        documentMutationPermissions={documentMutationPermissions}
        handleSetShowPreviewImage={handleSetShowPreviewImage}
        selected={state}
        onClick={bindSelectedOnClick(photo.doc, state)}
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />);
    },
    [selected, documentPermissions, documentMutationPermissions]
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const {onClick, ...rootProps} = getRootProps();

  if (documentMutationPermissions.loading) {
    return (<React.Fragment />);
  }
  // console.log("queryDocuments", {queryDocuments});
  // console.log("documents", documents);

  function bindSelectedOnClick(doc, state) {
    return (e) => {
      if (state) {
        setSelected(selected.filter((id) => id !== doc.id));
      } else {
        setSelected(selected.concat(doc.id));
      }
    };
  }

  function handleSelectAll() {
    if (documents.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(documents.map((d) => d.id));
    }
  }

  function bindUpdateDocuments(input) {
    return () => {
      return mutationUpdateDocument({
        variables: {
          ids: selected,
          input: input,
        },
        refetchQueries: [getOperationName(getDocumentsForLocationQuery)],
      });
    };
  }
  async function handleDeletePrompt() {
    //prompt?
    setShowDeletePrompt(true);
  }
  async function handleHideDeletePrompt() {
    setShowDeletePrompt(false);
  }
  async function handleDeleteSelected() {
    //prompt?
    await mutationDeleteDocuments({
      variables: {
        ids: selected,
      },
      refetchQueries: [getOperationName(getDocumentsForLocationQuery)],
    });
    handleHideDeletePrompt();
  }
  function handleDownloadSelection() {
    documents.filter((doc) => selected.indexOf(doc.id) > -1).forEach((doc) => {
      window.open(doc.urls.download.path);
    });
  }
  function handleDownloadSelected(type) {
    return () => {
      window.open(`${config.apiPath}/file.api/documents/download?type=${type}&id=${selected.join(",")}`);
      setShowDownloadSelection(false);
    };
  }
  function handleSetShowDownloadSelection(v) {
    return () => {
      setShowDownloadSelection(v);
    };
  }
  return (<React.Fragment>
    <Modal size="lg" show={!!showPreviewImage}>
      <Modal.Body>
        <img className="img-fluid" src={showPreviewImage} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSetShowPreviewImage(undefined)}>{t("button.close")}</Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showDownloadSelection}>
      <Modal.Body>
        {t("document.title.download")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSetShowDownloadSelection(false)}>{t("button.cancel")}</Button>
        <Button variant="success" onClick={handleDownloadSelected("web")}>{t("button.download.web")}</Button>
        <Button variant="success" onClick={handleDownloadSelected("print")}>{t("button.download.print")}</Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showDeletePrompt}>
      <Modal.Body>
        {t("document.title.delete")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleHideDeletePrompt}>{t("button.cancel")}</Button>
        <Button variant="danger" onClick={handleDeleteSelected}>{t("button.remove")}</Button>
      </Modal.Footer>
    </Modal>
    <div className="tab-section">
      <div disabled={!documentMutationPermissions.hasFunction("requestUploadDocument")} {...rootProps} className={"fh fh-element no-outline"}>
        <input {...getInputProps()} />
        <Container fluid className="no-gutters fh fh-element">
          <Row>
            <Col xs={"auto"}>
              <ButtonGroup>
                {documentMutationPermissions.hasFunction("requestUploadDocument") && <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.upload")}
                    </Tooltip>
                  }
                >
                  <Button onClick={onClick} variant="outline-primary">
                    <i className="fas fa-fw fa-upload" />
                  </Button>
                </OverlayTrigger>}

                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {documents.length === selected.length ? t("button.document.deselect-all") : t("button.document.select-all")}
                    </Tooltip>
                  }
                >
                  <Button onClick={handleSelectAll} variant={
                    documents.length === selected.length ? "primary" : "outline-primary"
                  }>
                    <i className="fas fa-fw fa-ballot-check" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
            {selected.length > 0 && (<Col xs="auto" className="ml-auto">
              <ButtonGroup className="ml-auto">
                {documentMutationPermissions.hasFunction("canPublish") && (<OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.unpublish")}
                    </Tooltip>
                  }
                >
                  <Button onClick={bindUpdateDocuments({published: false})} variant="outline-primary">
                    <i className="fas fa-fw fa-times-hexagon" />
                  </Button>
                </OverlayTrigger>)}
                {documentMutationPermissions.hasFunction("canPublish") && (<OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.publish")}
                    </Tooltip>
                  }
                >
                  <Button onClick={bindUpdateDocuments({published: true})} variant="outline-primary">
                    <i className="fas fa-fw fa-check-double" />
                  </Button>
                </OverlayTrigger>)}

                {documentMutationPermissions.hasFunction("update") && (<OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.unlock")}
                    </Tooltip>
                  }
                >
                  <Button onClick={bindUpdateDocuments({released: true, processed: false})} variant="outline-primary">
                    <i className="fas fa-fw fa-lock-open" />
                  </Button>
                </OverlayTrigger>)}
                {documentMutationPermissions.hasFunction("update") && (<OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.lock")}
                    </Tooltip>
                  }
                >
                  <Button onClick={bindUpdateDocuments({released: false, processed: false})} variant="outline-primary">
                    <i className="fas fa-fw fa-lock" />
                  </Button>
                </OverlayTrigger>)}
                {documentMutationPermissions.hasFunction("destroy") && (<OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.delete")}
                    </Tooltip>
                  }
                >
                  <Button onClick={handleDeletePrompt} variant="outline-primary">
                    <i className="fas fa-fw fa-trash-alt" />
                  </Button>
                </OverlayTrigger>)}
                {documents.filter((d) => selected.indexOf(d.id) > -1 && !d.released).length === 0 && <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      {t("button.document.download")}
                    </Tooltip>
                  }
                >
                  <Button onClick={handleSetShowDownloadSelection(true)} variant="outline-primary">
                    <i className="fas fa-fw fa-download" />
                  </Button>
                </OverlayTrigger>}
              </ButtonGroup>
            </Col>)}
          </Row>
          {isDragActive && (<Row>
            <Col>
              <div style={{border: "dashed #eee 2px", margin: 15, padding: 30, textAlign: "center"}}>{"Drop here to upload"}</div>
            </Col>
          </Row>)}
          {files.length > 0 && (<Row >
            <Col xs={12}>
              <Container fluid>
                <Row >
                  {files.map((f) => (<UploadRow
                    key={f.id}
                    fileData={f}
                    locationId={locationId}
                    floorPlan={floorPlan}
                    onUploadComplete={handleCompleteUpload}
                    onUploadError={handleErrorUpload}
                    mutationUpdateDocument={mutationUpdateDocument} />))}
                </Row>
              </Container>
            </Col>
          </Row>)}
          <Row className={"scrollable"}>
            <Col>
              <AutoSizer>
                {({width, height}) => {
                  if ((documents || []).length === 0 || width === 0 || height === 0) {
                    return (<React.Fragment />);
                  }
                  return (<div style={{width, height}} className="scroll">
                    <Gallery direction={floorPlan ? "column" : undefined} photos={documents.map((d) => ({
                      key: d.id,
                      doc: d,
                      src: d.urls?.thumbnail?.path,
                      height: d.urls?.thumbnail ? d.urls.thumbnail.aspectRatio.h : 1,
                      width: d.urls?.thumbnail ? d.urls.thumbnail.aspectRatio.w : 1,
                    }))} renderImage={imageRenderer} />
                  </div>);
                }}
              </AutoSizer>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </React.Fragment>);
}


function UploadRow({locationId, floorPlan, fileData, onUploadComplete, onUploadError, mutationUpdateDocument}) {
  const [progress, setProgress] = useState(0);
  const {t} = useTranslation();
  const [mutationUploadRequest] = useMutation(requestUploadDocumentMutation);
  const [upload, setUpload] = useState(undefined);

  useEffect(() => {
    if (!upload && !fileData.complete) {
      console.log("start upload", fileData.id);
      (async() => {
        let height, width;
        try {
          const size = getImageSizeFromObjectUrl(fileData.preview);
          height = size.height;
          width = size.width;
        } catch (e) {
          console.error("unabled to get height and width", e);
        }
        const uploadRequestResponse = await mutationUploadRequest({
          variables: {
            locationId,
            floorplan: floorPlan,
            fileName: fileData.file.name,
            fileType: fileData.file.type,
            height,
            width,
          },
        });

        const {id, uploadTarget} = requestUploadDocumentResult(uploadRequestResponse);

        const xhr = new XMLHttpRequest();
        xhr.open("PUT", uploadTarget, true);
        xhr.overrideMimeType(fileData.file.type);
        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            const p = (e.loaded / e.total * 100).toFixed(2);
            setProgress(p);
          } else {
            setProgress(-1);
          }
        };

        xhr.upload.onabort = async(e) => {
          onUploadError(e, fileData, id);
        };
        xhr.upload.ontimeout = async(e) => {
          onUploadError(e, fileData, id);
        };
        xhr.upload.onerror = async(e) => {
          onUploadError(e, fileData, id);
        };

        xhr.onerror = async(e) => {
          onUploadError(e, fileData, id);
        };
        xhr.onload = async(e) => {
          await mutationUpdateDocument({
            variables: {
              ids: [id],
              input: {
                status: "Ready",
              },
            },
            refetchQueries: [getOperationName(getDocumentsForLocationQuery)],
            awaitRefetchQueries: true,
          });
          onUploadComplete(e, fileData, id);
        };
        xhr.send(fileData.file);
        setUpload(xhr);
      })();
    }
  }, []);
  if (fileData.complete) {
    return (<React.Fragment />);
  }

  return (<Col xs="auto">
    <Card style={{width: "18rem"}}>
      <Card.Img variant="top" src={fileData.preview} />
      <Card.Body>
        <ProgressBar animated variant={fileData.errored ? "danger" : "info"} now={progress < 0 ? 100 : progress}
          label={progress < 0 ? t("document.upload-unknown") : `${progress}%`} />
      </Card.Body>
    </Card>
  </Col>);
}



const Checkmark = ({selected}) => (
  <div
    style={
      selected
        ? {left: "4px", top: "4px", position: "absolute", zIndex: "1"}
        : {display: "none"}
    }
  >
    <svg
      style={{fill: "white", position: "absolute"}}
      width="24px"
      height="24px"
    >
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg
      style={{fill: "#06befa", position: "absolute"}}
      width="24px"
      height="24px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const imgStyle = {
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
const selectedImgStyle = {
  transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
};

function SelectedImage({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  onClick,
  permissions,
  documentMutationPermissions,
  handleSetShowPreviewImage,
}) {
  const {doc, ...rest} = photo;
  const {t} = useTranslation();
  // console.log("photo", photo);
  // const [isSelected, setIsSelected] = useState(selected);
  //calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100;
  const sy = (100 - (30 / photo.height) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const style = selected ? {...imgStyle, ...selectedImgStyle} : {...imgStyle};
  // console.log("doc", doc, permissions);
  return (
    <div
      style={{margin, height: photo.height, width: photo.width, ...cont}}
      className={`hideem-container ${!selected ? "not-selected" : ""}`}
    >
      <Checkmark selected={selected} />
      <div className="" style={{
        zIndex: 10,
        position: "absolute",
        right: 5,
        top: 5,
      }}>
        <ButtonGroup>
          {doc?.urls?.preview && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.preview")}
                </Tooltip>
              }
            >
              <a className="btn btn-secondary hideem" onClick={handleSetShowPreviewImage(doc.urls.preview.path)}>
                <i className="fas fa-fw fa-search" />
              </a>
            </OverlayTrigger>)}
          {doc?.urls?.print && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.download.print")}
                </Tooltip>
              }
            >
              <a className="btn btn-success hideem" href={doc.urls.print.path} download={doc.name}>
                <i className="fas fa-fw fa-file-export" />
              </a>
            </OverlayTrigger>)}
          {doc?.urls?.web && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.download.web")}
                </Tooltip>
              }
            >
              <a className="btn btn-success hideem" href={doc.urls.web.path} download={doc.name}>
                <i className="fas fa-fw fa-file-medical" />
              </a>
            </OverlayTrigger>)}
          {/* {documentMutationPermissions.hasFunction("destroy") && <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip>
                {t("document.delete")}
              </Tooltip>
            }
          >
            <Button className="hideem" variant="danger">
              <i className="fas fa-fw fa-trash-alt"/>
            </Button>
          </OverlayTrigger>} */}
          {permissions.hasField("processed") && !doc.processed && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.processing")}
                </Tooltip>
              }
            >
              <Button variant="secondary">
                <i className="fas fa-fw fa-cog fa-spin"/>
              </Button>
            </OverlayTrigger>)}
          {permissions.hasField("published") && !doc.published && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.not-published")}
                </Tooltip>
              }
            >
              <Button variant="danger">
                <i className="fas fa-fw fa-times"/>
              </Button>
            </OverlayTrigger>)}
          {permissions.hasField("released") && !doc.released &&
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip>
                  {t("document.locked")}
                </Tooltip>
              }
            >
              <Button variant="warning">
                <i className="fas fa-fw fa-lock"/>
              </Button>
            </OverlayTrigger>}
        </ButtonGroup>
      </div>
      {doc.type === "Image" && (<img
        alt={photo.title}
        style={style}
        {...rest}
        onClick={onClick}
      />)}
      {doc.type === "Document" && (<Card
        style={{
          height: photo.height,
          width: photo.width,
          ...style,
        }}
        onClick={onClick}
      >
        {/* <Card.Img variant="top" src={preview} /> */}
        <Card.Body>
          <Card.Text>{doc.name}</Card.Text>
        </Card.Body>
      </Card>)}
    </div>
  );
}
